<template>
  <section class="section" :style="{ background: background }">
    <div class="container__bottom" id="faq">
      <h1 class="title">Frequently Asked Question</h1>
      <hr />
      <section>
        <aside v-if="faqs.teams.length">
          <h2 class="heading">TEAMS</h2>
          <div v-for="(faq, index) in faqs.teams" :key="index">
            <input type="checkbox" :id="`team-faq${index}`" class="faq-input" />
            <label class="faq-label" :for="`team-faq${index}`">
              <h2>{{ faq.question }}</h2>
              <p class="answer" v-html="faq.answer"></p>
            </label>
          </div>
        </aside>
        <aside v-if="faqs.licenses.length">
          <h2 class="heading">LICENSES</h2>
          <div :key="index" v-for="(faq, index) in faqs.licenses">
            <input
              type="checkbox"
              :id="`license-faq${index}`"
              class="faq-input"
            />
            <label class="faq-label" :for="`license-faq${index}`">
              <h2>{{ faq.question }}</h2>
              <p class="answer" v-html="faq.answer"></p>
            </label>
          </div>
        </aside>
        <aside v-if="faqs.using.length">
          <h2 class="heading">USING {{ productname }}</h2>
          <div :key="index" v-for="(faq, index) in faqs.using">
            <input type="checkbox" :id="`using${index}`" class="faq-input" />
            <label class="faq-label" :for="`using${index}`">
              <h2>{{ faq.question }}</h2>
              <p class="answer" v-html="faq.answer"></p>
            </label>
          </div>
        </aside>
        <aside>
          <p class="ask-question">
            Have more questions?
            <a
              class="highlight"
              :style="{ color: theme }"
              :href="emailstring"
              target="_top"
              >Kindly contact our customer care service</a
            >
          </p>
        </aside>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: "Faq",
  props: {
    faqs: {
      type: Object,
      default: () => {},
      required: true,
    },
    background: {
      type: String,
      default: "#0c0c0c",
    },
    emailstring: {
      type: String,
      default: "",
      required: true,
    },
    theme: {
      type: String,
      default: "",
      required: true,
    },
    productname: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.section,
.download {
  width: 100%;
  min-height: 100vh;
  background: $lr-background-dark;
}
.container__bottom {
  @include max-container;
  padding-top: 12.5rem;
  .title {
    font-size: 4.25rem;
    font-weight: bold;
    line-height: 8.9rem;
    padding: 1.5rem 0 1.5rem 2rem;
    color: $color-white;

    @include respond(phone) {
      font-size: 3rem;
      line-height: 4.6rem;
    }
  }

  section {
    display: inline-block;
  }

  hr {
    border-top: 1px solid #e8e8e8;
  }
  section {
    aside {
      .heading {
        color: #c1c9cf;
        font-weight: 500;
        font-size: 16px;
        margin: 2rem 0 1rem;
        padding-left: 2rem;
      }

      .faq-label {
        padding: 2.3rem;
        margin: 1px 0;
        position: relative;
        cursor: pointer;
        transition: background 300ms;
        width: 100%;

        h2 {
          font-size: 2rem;
          margin-bottom: 1rem;
          color: $color-white;
        }
        p {
          font-size: 1.8rem;
          position: relative;
          transition: 500ms;
          height: 1px;
          top: -1rem;
          overflow: hidden;
          color: rgba($color-white, 0.8);
          margin-bottom: 0;
        }

        &::after {
          content: "\002B";
          position: absolute;
          right: 2rem;
          top: 1.5rem;
          font-size: 3rem;
          transition: all 500ms;
          color: rgba($color-white, 0.4);
          opacity: 0.4;

          @include respond(phone) {
            right: 1rem;
          }
        }
      }

      .faq-input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;

        &:checked + .faq-label {
          background: rgba($color-white, 0.1);
          border-radius: 1.8rem;
          p {
            height: max-content;
            transform: translateY(1rem);
            opacity: 1;
          }

          &::after {
            transform: rotateZ(135deg);
            opacity: 1;
            color: rgba($color-white, 1);
          }
        }
      }

      .ask-question {
        color: $color-white;
        font-size: 2.4rem;
        padding-left: 2rem;
        font-weight: 500;
        margin: 4rem 0 6rem;
        .highlight {
          text-decoration: unset;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
